<template>
  <v-card rounded="lg">
    <v-card-text v-if="details.profile">
      <v-list dense disabled>
        <v-list-item-group v-model="details">
          <v-list-item v-for="(data, field) in info" :key="field">
            <v-list-item-icon>
              <v-icon v-text="data.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="$t(`label.${field}`)"></v-list-item-title>
              <v-list-item-subtitle v-text="data.value"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  computed: {
    details() {
      return this.$store.getters['details/list'] || {}
    },
    info() {
      return Object.fromEntries(Object.entries(this.details.profile).filter(([index]) => this.fields.includes(index)))
    }
  },
  data: () => ({
    fields: [
      'email',
      'phone',
    ]
  }),
}
</script>